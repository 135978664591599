import React from 'react';
import { Button } from 'antd';
import jsPDF from 'jspdf';
import moment from 'moment';

import { Icon } from '../../../components';
import { premiseTypeMap } from '../../../helpers';

const getStatusName = (status) => {
  if (status === 'PAID') {
    return 'Zapłacone';
  } else if (status === 'PARTIALLY_PAID') {
    return 'Częściowo zapłacone';
  } else {
    return 'Do zapłaty';
  }
};

export const SettlementPdfGenerator = ({
  investmentData,
  premisesData,
  settlementData,
  settlementCosts,
}) => {
  const title = moment(settlementData.createDate)
    .locale('pl_PL')
    .format('MMMM YYYY');

  const generatePdf = () => {
    const doc = new jsPDF();
    doc.setFont('Overpass-Regular');

    doc.setFontSize(15);
    doc.text(title.charAt(0).toUpperCase() + title.slice(1), 15, 20);
    doc.text(`(wystawiono: ${settlementData.createDate})`, 135, 20);

    doc.line(13, 25, 197, 25);

    let firstColumnY = 35;
    let secondColumnY = 35;
    let thirdColumnY = 35;

    doc.setFontSize(12);
    doc.text('Odbiorca', 15, firstColumnY);

    doc.setFontSize(5.5);
    doc.text('Rozliczenie dla:', 15, (firstColumnY += 7));

    doc.setFontSize(8.5);

    const ownerList = [
      premisesData.premisesOwner?.firstName,
      premisesData.premisesOwner?.lastName,
    ].filter((el) => !!el);

    doc.text(
      ownerList.length !== 0 ? ownerList.join(' ') : '-',
      15,
      (firstColumnY += 5)
    );
    doc.text(
      `ul. ${premisesData.address?.street} ${
        premisesData.address?.number || ''
      }${premisesData.address?.local ? `/${premisesData.address?.local}` : ''}`,
      15,
      (firstColumnY += 4)
    );
    doc.text(
      `${premisesData.address?.zip} ${premisesData.address?.city}, ${premisesData.address?.country}`,
      15,
      (firstColumnY += 4)
    );

    doc.setFontSize(5.5);
    doc.text('Lokal:', 73, (secondColumnY += 7));

    doc.setFontSize(8.5);

    let splitText = doc.splitTextToSize(
      `${
        settlementData.investmentType === 'HOUSING_ASSOCIATION'
          ? 'Spółdzielnia'
          : 'Wspólnota'
      } ${settlementData.companyName}`,
      55
    );

    doc.text(splitText, 73, (secondColumnY += 5));
    doc.text(
      `${premiseTypeMap(premisesData.type)} ${premisesData.name}`,
      73,
      (secondColumnY += 4 + 3.5 * (splitText.length - 1))
    );
    doc.text(
      `ul. ${premisesData.address?.street} ${
        premisesData.address?.number || ''
      }${premisesData.address?.local ? `/${premisesData.address?.local}` : ''}`,
      73,
      (secondColumnY += 4)
    );

    const temp = Math.max(firstColumnY, secondColumnY);
    firstColumnY = temp;
    secondColumnY = temp;

    doc.setFontSize(5.5);
    doc.text('Dane kontaktowe:', 15, (firstColumnY += 7));

    doc.setFontSize(8.5);
    doc.text(
      premisesData.premisesOwner?.mobile ?? '-',
      15,
      (firstColumnY += 5)
    );
    doc.text(premisesData.premisesOwner?.email ?? '-', 15, (firstColumnY += 4));

    doc.setFontSize(5.5);
    doc.text('Powierzchnia:', 73, (secondColumnY += 7));

    doc.setFontSize(8.5);
    doc.text(`${premisesData.totalSurface}`, 73, (secondColumnY += 5));
    doc.text(
      'm',
      73 + 1.5 * premisesData.totalSurface?.toString().length,
      secondColumnY
    );
    doc.setFontSize(5.5);
    doc.text(
      '2',
      75.5 + 1.5 * premisesData.totalSurface?.toString().length,
      (secondColumnY -= 1)
    );

    doc.setFontSize(12);
    doc.text('Nadawca:', 135, 35);

    doc.setFontSize(5.5);
    doc.text(
      `Dane ${
        settlementData.investmentType === 'HOUSING_ASSOCIATION'
          ? 'Spółdzielni'
          : 'Wspólnoty'
      }`,
      135,
      (thirdColumnY += 7)
    );

    doc.setFontSize(8.5);

    splitText = doc.splitTextToSize(investmentData.name, 55);

    doc.text(splitText, 135, (thirdColumnY += 5));

    doc.text(
      `ul. ${investmentData.address?.street} ${
        investmentData.address?.number || ''
      }${
        investmentData.address?.local ? `/${investmentData.address?.local}` : ''
      }`,
      135,
      (thirdColumnY += 4 + 3.5 * (splitText.length - 1))
    );
    doc.text(
      `${investmentData.address?.zip} ${investmentData.address?.city}, ${investmentData.address?.country}`,
      135,
      (thirdColumnY += 4)
    );

    doc.text(`NIP: ${investmentData.nip ?? '-'}`, 135, (thirdColumnY += 7));
    doc.text(`Regon: ${investmentData.regon ?? '-'}`, 135, (thirdColumnY += 4));

    firstColumnY = Math.max(firstColumnY, secondColumnY, thirdColumnY);

    doc.line(13, (firstColumnY += 5), 197, firstColumnY);

    doc.setFontSize(12);
    doc.text('Płatność', 15, (firstColumnY += 8));

    doc.setFontSize(5.5);
    if (settlementData.price < 0) {
      doc.text('Nadpłata:', 15, (firstColumnY += 7));
    } else {
      doc.text('Razem do zapłaty:', 15, (firstColumnY += 7));
    }
    secondColumnY = firstColumnY;
    thirdColumnY = firstColumnY;

    doc.setFontSize(11);
    doc.text(
      `${
        settlementData.price
          ? Math.abs(settlementData.price).toFixed(2).replace('.', ',')
          : '0,00'
      } zł`,
      15,
      (firstColumnY += 5)
    );

    doc.setFontSize(5.5);
    doc.text('Termin płatności:', 15, (firstColumnY += 7));

    doc.setFontSize(8.5);
    doc.text(settlementData.paymentDate, 15, (firstColumnY += 4));

    doc.setFontSize(5.5);
    doc.text('Zapłacono:', 73, secondColumnY);

    doc.setFontSize(11);
    if (settlementData.price < 0) {
      doc.text('-', 73, (secondColumnY += 5));
    } else {
      doc.text(
          `${
              settlementData.paid
                  ? settlementData.paid.toFixed(2).replace('.', ',')
                  : '0,00'
          } zł`,
          73,
          (secondColumnY += 5)
      );
    }

    doc.setFontSize(5.5);
    doc.text('Data opłacenia:', 73, (secondColumnY += 7));

    doc.setFontSize(8.5);
    doc.text(settlementData.paidDate ?? '-', 73, (secondColumnY += 4));

    doc.setFontSize(5.5);
    if (settlementData.price < 0) {
      doc.text('Nadpłata:', 135, thirdColumnY);
    } else {
      doc.text('Pozostało do zapłaty:', 135, thirdColumnY);
    }

    doc.setFontSize(11);
    doc.text(
      `${
        settlementData.price
          ? Math.abs(settlementData.price - settlementData.paid)
              .toFixed(2)
              .replace('.', ',')
              .replace('-', '+')
          : '0,00'
      } zł`,
      135,
      (thirdColumnY += 5)
    );

    doc.setFontSize(5.5);
    doc.text('Status:', 135, (thirdColumnY += 7));

    doc.setFontSize(11);
    doc.text(getStatusName(settlementData.status), 135, (thirdColumnY += 5));

    doc.setFontSize(5.5);
    doc.text('Tytuł przelewu:', 15, (firstColumnY += 9));

    doc.setFontSize(11);
    doc.text(settlementData.name, 15, (firstColumnY += 5));

    doc.setFontSize(5.5);
    doc.text('Rachunek do wpłat:', 15, (firstColumnY += 6));

    doc.setFontSize(11);
    doc.text(settlementData.bankNumber, 15, (firstColumnY += 5));

    doc.line(13, (firstColumnY += 6), 197, firstColumnY);

    doc.setFontSize(12);
    doc.text('Tabela kosztów', 15, (firstColumnY += 9));

    doc.autoTable({
      startY: (firstColumnY += 5),
      head: [
        [
          'Pozycja',
          'Ilość',
          'Jednostka',
          'Cena jednostkowa (brutto)',
          'Wartość (brutto)',
        ],
      ],
      body: settlementCosts?.map((settlementCost) => [
        settlementCost.name,
        settlementCost.quantity,
        settlementCost.unit === 'Opłata stała' ? 'razy' : settlementCost.unit,
        settlementCost.unitPrice
          ? `${settlementCost.unitPrice.toFixed(2).replace('.', ',')} zł`
          : '',
        settlementCost.price
          ? `${settlementCost.price.toFixed(2).replace('.', ',')} zł`
          : '',
      ]),

      theme: 'striped',
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        font: 'Overpass-Regular',
        fontSize: 6,
        cellPadding: {
          top: 1,
          left: 1.5,
          bottom: 1,
        },
      },
      alternateRowStyles: {
        fillColor: [230, 230, 230],
      },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 6,
        fontStyle: 'bold',
        cellPadding: {
          bottom: 3,
          left: 1.5,
          top: 3,
        },
      },
    });

    doc.setFontSize(12);
    const month = moment(settlementData.createDate).locale('pl_PL').format("MMMM");
    if (settlementData.price < 0) {
      doc.text(`Nadpłata za ${month}:`, 100, doc.lastAutoTable.finalY + 10);
    } else {
      doc.text(`Razem za ${month}:`, 100, doc.lastAutoTable.finalY + 10);
    }
    doc.text(
      `${
        settlementData.price
          ? Math.abs(settlementData.price).toFixed(2).replace('.', ',')
          : '0,00'
      } zł`,
      165,
      doc.lastAutoTable.finalY + 10
    );
    const endPrice = settlementData.price + settlementData.userBalance;

    if (settlementData.userBalance > 0) {
      doc.setTextColor(255,0,0);
      doc.text(`Saldo na ${settlementData.createDate}:`, 100, doc.lastAutoTable.finalY + 16);
      doc.text(
          `${ settlementData.userBalance.toFixed(2).replace('.', ',')} zł`,
          165,
          doc.lastAutoTable.finalY + 16
      );
      doc.setTextColor(0,0,0);

      doc.text('Razem do zapłaty:', 100, doc.lastAutoTable.finalY + 22);
      doc.text(
          `${ Math.abs(endPrice).toFixed(2).replace('.', ',')
          } zł`,
          165,
          doc.lastAutoTable.finalY + 22
      );

    } else if (settlementData.userBalance < 0) {
      doc.text(`Saldo na ${settlementData.createDate}:`, 100, doc.lastAutoTable.finalY + 16);
      doc.text(
          `${ Math.abs(settlementData.userBalance).toFixed(2).replace('.', ',')} zł`,
          165,
          doc.lastAutoTable.finalY + 16
      );

      doc.setTextColor(0,255,0);
      doc.text('Całkowita nadpłata:', 100, doc.lastAutoTable.finalY + 22);
      doc.text(
          `${ Math.abs(endPrice).toFixed(2).replace('.', ',')
          } zł`,
          165,
          doc.lastAutoTable.finalY + 22
      );
      doc.setTextColor(0,0,0);
    }

    const numberOfPages = doc.internal.getNumberOfPages();
    const footerText = `crm.manage.com.pl/crm/investment/${settlementData.investmentId}/premises/${settlementData.premisesId}/housingSettlements/${settlementData.id}/show`;

    doc.setFontSize(5.5);

    for (let i = 1; i <= numberOfPages; i++) {
      doc.setPage(i);

      doc.text('Manage', 195, 8);

      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();

      doc.text(footerText, 7, pageHeight - 8);

      const pageNumbering = `${i}/${numberOfPages}`;
      const textWidth =
        (doc.getStringUnitWidth(pageNumbering) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      doc.text(pageNumbering, pageWidth - 7 - textWidth, pageHeight - 8);
    }

    doc.output('dataurlnewwindow', 'Rozliczenie.pdf');
  };

  return (
    <Button
      className='button-secondary'
      onClick={generatePdf}
      style={{ minWidth: 0, maxWidth: 48 }}
    >
      <Icon name='print' style={{ paddingLeft: 0 }} />
    </Button>
  );
};
