import React, { useEffect, useRef, useState } from 'react';
import { PageHeader, Button, Dropdown, Menu, Modal } from 'antd';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Icon, Datagrid } from '../../../../components';
import Spin from '../../../../components/Spin';
import mainCountersService from '../../../../services/mainCountersService';
import subCountersService from '../../../../services/subCountersService';
import subCountersHistoryService from '../../../../services/subCountersHistoryService';
import PermisesService from '../../../../services/premisesService';
import { renderUnit } from '../../../../helpers/unitRender';

import { SubCounterCard } from '../../components/SubCounterCard';
import SubCounterReadModal from '../../components/SubCounterReadModal';

const { confirm } = Modal;

const percentRender = (percent) => {
  const value = parseFloat(percent);
  if (value >= 0) {
    return `+${value.toFixed(2)}%`;
  } else {
    return `${value.toFixed(2)}%`;
  }
};

const Show = () => {
  const history = useHistory();
  const { id, counterId, subCounterId } = useParams();
  const datagridRef = useRef();
  const [canEdit, setCanEdit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [mainCounterData, setMainCounterData] = useState({});
  const [subCounterData, setSubCounterData] = useState({});
  const [premiseData, setPremiseData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editingRead, setEditingRead] = useState();
  const [allReads, setAllReads] = useState([]);

  const columns = [
    {
      title: <span>Stan licznika ({renderUnit(mainCounterData?.unit)})</span>,
      exportTitle: `Stan licznika (${mainCounterData?.unit})`,
      dataIndex: 'value',
      visible: true,
      filterEnabled: false,
      key: 'value',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <span>
          {record.subCounterValueChangePercent !== 0 && (
            <div
              style={{
                position: 'absolute',
                top: 10,
                color:
                  record.subCounterValueChangePercent < 0
                    ? '#EC434C'
                    : '#15D55E',
                fontSize: 8,
                fontWeight: 700,
              }}
            >
              {percentRender(record.subCounterValueChangePercent)}
            </div>
          )}

          <div>
            {parseFloat(record.value).toFixed(2)} {renderUnit(record.unit)}
          </div>
        </span>
      ),
    },
    {
      title: 'Stan licznika od',
      filterProps: { addonAfter: renderUnit(mainCounterData?.unit) },
      dataIndex: 'valueFrom',
      visible: false,
      filterEnabled: true,
      key: 'valueFrom',
      sorter: false,
      export: false,
    },
    {
      title: 'Stan licznika do',
      filterProps: { addonAfter: renderUnit(mainCounterData?.unit) },
      dataIndex: 'valueTo',
      visible: false,
      filterEnabled: true,
      key: 'valueTo',
      sorter: false,
      export: false,
    },
    {
      title: (
        <span>
          Różnica od ostatniego odczytu ({renderUnit(mainCounterData?.unit)})
        </span>
      ),
      exportTitle: `Ostatni odczyt (${mainCounterData?.unit})`,
      dataIndex: 'subCounterValue',
      visible: true,
      filterEnabled: false,
      key: 'subCounterValue',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (text ? parseFloat(text).toFixed(2) : '-'),
    },
    {
      title: 'Data odczytu',
      dataIndex: 'readDate',
      visible: true,
      filterEnabled: false,
      key: 'readDate',
      sorter: false,
      render: (text, record) => (text ? text : '-'),
      exportRender: (text, record) => (text ? text : '-'),
    },
  ];

  const refresh = () => {
    datagridRef.current.refresh();
  };

  const handleOk = (historyId) => {
    const SubCountersHistoryService = new subCountersHistoryService(
      id,
      counterId,
      subCounterId
    );
    SubCountersHistoryService.delete(historyId).then((response) => {
      refresh();
    });
  };

  const showDeleteConfirm = (record) => {
    confirm({
      title: `Usunąć wybraną pozycje?`,
      content: 'Po kliknięciu „Usuń”, przywrócenie nie będzie możliwe.',
      okText: 'Usuń',
      okType: 'danger',
      cancelText: 'Anuluj',
      icon: false,
      width: 320,
      className: 'delete-confirm-modal',
      cancelButtonProps: { className: 'button-secondary', size: 'large' },
      okButtonProps: { size: 'large' },
      onOk() {
        handleOk(record.id);
      },
    });
  };

  const menu = (record) => (
    <Menu>
      {
        <Menu.Item
          key='98'
          onClick={() => {
            setEditingRead(record);
            setShowModal(true);
          }}
          icon={<Icon name='edit' style={{ marginRight: '5px' }} />}
        >
          Edytuj
        </Menu.Item>
      }
      {
        <Menu.Item
          key='99'
          style={{ color: '#EC434C' }}
          icon={<Icon name='delete' style={{ marginRight: '5px' }} />}
          onClick={() => showDeleteConfirm(record)}
        >
          Usuń
        </Menu.Item>
      }
    </Menu>
  );

  useEffect(() => {
    const MainCountersService = new mainCountersService(id);
    MainCountersService.getOne(counterId).then((data) => {
      setMainCounterData(data);
      setLoading(false);
    });
  }, [showModal]);

  useEffect(() => {
    const SubCountersService = new subCountersService(id, counterId);
    SubCountersService.getOne(subCounterId).then((data) => {
      setSubCounterData(data);
      setLoading(false);
    });
  }, [showModal]);

  useEffect(() => {
    const SubCountersHistoryService = new subCountersHistoryService(
      id,
      counterId,
      subCounterId
    );
    SubCountersHistoryService.getList().then((data) => {
      setAllReads(
        data.content.sort((a, b) => (a.readDate > b.readDate ? -1 : 1))
      );
      setLoading(false);
    });
  }, [showModal]);

  useEffect(() => {
    if (subCounterData.premisesId) {
      const PremisesService = new PermisesService(id);

      PremisesService.getOne(subCounterData.premisesId).then((response) => {
        setPremiseData(response);
      });
    }
  }, [subCounterData]);

  const HeaderTitle = () => {
    if (!subCounterData.number) {
      return <span>Podlicznik</span>;
    }
    return (
      <span>
        Podlicznik:{' '}
        <span className='header-subtitle'>{subCounterData.number}</span>
      </span>
    );
  };

  const AddReadButton = () => {
    return (
      <Button
        type='primary'
        onClick={() => setShowModal(true)}
        style={{ marginBottom: 20 }}
      >
        Dodaj odczyt <Icon name='plus' style={{ marginLeft: 10 }} />
      </Button>
    );
  };

  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spin />
        </div>
      ) : (
        <>
          <div>
            <PageHeader
              ghost={false}
              backIcon={<Icon name='arrow-simply-left' />}
              onBack={() => window.history.back()}
              title={<HeaderTitle />}
              extra={[
                <Link
                  key='2'
                  to={`/investment/${id}/counters/${counterId}/subCounters/${subCounterId}/edit`}
                >
                  <Button type='primary' className='counters-edit-btn'>
                    Edytuj <Icon name='edit' />
                  </Button>
                </Link>,
              ]}
            >
              <SubCounterCard
                name={mainCounterData.name}
                number={subCounterData.number}
                premiseName={premiseData.name}
                premiseType={premiseData.type}
                countersValuesSum={subCounterData.countersValuesSum}
                countersPreviousValuesSumPercent={
                  subCounterData.countersPreviousValuesSumPercent
                }
                unit={mainCounterData.unit}
              />
            </PageHeader>
          </div>
          <div className='subcounter-history-datagrid'>
            <SubCounterReadModal
              isModalVisible={showModal}
              afterCancel={() => {
                setShowModal(false);
                setEditingRead(null);
              }}
              allReads={allReads}
              mainCounterData={mainCounterData}
              subCounterData={subCounterData}
              editingRead={editingRead}
              refresh={refresh}
            />
            <Datagrid
              ref={datagridRef}
              title={'Historia odczytów'}
              exportTitle={'Historia odczytów'}
              resource={`investment/${id}/counters/${counterId}/subCounters/${subCounterId}/history`}
              dataProvider={
                new subCountersHistoryService(id, counterId, subCounterId)
              }
              columns={columns}
              sort={{
                sortField: 'id',
                sortOrder: 'descend',
              }}
              hideReset={true}
              hideSms={true}
              hideEmail={true}
              hideEdit
              hideShow
              customRowActions={[
                (record) => (
                  <Dropdown
                    overlay={() => menu(record)}
                    trigger='click'
                    placement='bottomCenter'
                    className='smalllist-wrapper-row-action'
                  >
                    <Icon name={'more-vertical'} />
                  </Dropdown>
                ),
              ]}
              customCreate={<AddReadButton />}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Show;
